<template>
    <footer id="p-footer" class="p-footer bg-dark">
        <div class="footer-for-group">
            <div class="top-part">
                <div class="container">
                    <div class="c-grid">
                        <div class="cell sm-12 md-5 lg-5 xl-o-1 xl-4">
                            <div class="p-contact-nav">
                                <div class="f-label">{{ "Pondera group" }}</div>
                                <div class="f-menu">
                                    <ElementsFooterLinks
                                        v-if="!!menus__.menu_footer.length"
                                        :links="menus__.menu_footer"
                                    />
                                </div>

                                <div class="f-links">
                                    <NuxtLink
                                        :to="settings__.linkedin"
                                        target="_blank"
                                        rel="noopener"
                                        class="linked-in-link psb-20"
                                    >
                                        <span class="icon"><IconsLinkedin /></span>
                                        <span class="text">{{ "LinkedIn" }}</span>
                                    </NuxtLink>

                                    <a
                                        :href="`tel:` + settings__.phone[0]?.linkUrl"
                                        :title="settings__.phone[0]?.linkText"
                                        class="f-link"
                                        >{{ settings__.phone[0]?.linkText }}</a
                                    >
                                    <a
                                        :href="`mailto:` + settings__.emailGroup"
                                        :title="settings__.emailGroup"
                                        class="f-link"
                                        >{{ settings__.email }}</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="cell sm-12 md-6 lg-7 xl-o-1 xl-5">
                            <div v-if="settings__.offices.length" class="p-offices-grid">
                                <div class="office" v-for="(office, index) in settings__.offices" :key="index">
                                    <div class="f-label">{{ office.place }}</div>
                                    <address class="address" v-html="nl2br(office.address)"></address>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="middle-part">
                <div class="c-grid">
                    <div class="cell sm-12 md-5 lg-5 xl-o-1 xl-4">
                        <div class="f-label">{{ "Newsletter" }}</div>
                        <FormsNewsletter />
                    </div>
                </div>
            </div>
            <div class="bottom-part">
                <div class="c-grid">
                    <div class="cell sm-12 md-12 lg-12 xl-o-1 xl-10">
                        <NuxtLink :to="localePath({ name: 'index' })" class="f-logo">
                            <IconsSmallLogo />
                        </NuxtLink>
                        <div class="p-copyright-nav">
                            <div class="copyright" v-html="convertStrYear(settings__.copyright)"></div>
                            <div class="menu">
                                <nav class="nav">
                                    <ul>
                                        <li v-for="(item, index) in menus__.menu_links" :key="index">
                                            <NuxtLink :to="useConvertedLink(item.element, item)" class="item-link">
                                                <span class="text">{{ item.title }}</span>
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
    const localePath = useLocalePath();
    const store = useGlobalStore();
    const { menus, settings, languageCode } = storeToRefs(store);

    const settings__ = computed(() => settings.value[languageCode.value][0]);
    const menus__ = computed(() => menus.value[languageCode.value]);
</script>
