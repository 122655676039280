<template>
    <div class="p-mobile-navigation group">
        <div class="menu">
            <nav class="c-navigation">
                <ul class="parents">
                    <li v-for="item in menus__.menu_general" :key="item.id" class="item">
                        <NuxtLink :to="useConvertedLink(item.element, item)" class="item-link">
                            <span class="text">{{ item.title }}</span>
                        </NuxtLink>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="contact">
            <div class="links">
                <NuxtLink :to="settings__.linkedin" target="_blank" rel="noopener" class="linked-in-link">
                    <span class="icon"><IconsLinkedin :fill="'var(--p-dark)'" /></span>
                    <span class="text">{{ "LinkedIn" }}</span>
                </NuxtLink>
                <NuxtLink :to="`tel:` + settings__.phone[0]?.linkUrl" :title="settings__.phone[0]?.linkText">{{
                    settings__.phone[0]?.linkText
                }}</NuxtLink>
                <NuxtLink :to="`mailto:` + settings__.emailGroup" :title="settings__.emailGroup">{{
                    settings__.emailGroup
                }}</NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup>
    const store = useGlobalStore();
    const { menus, settings, languageCode } = storeToRefs(store);

    const settings__ = computed(() => settings.value[languageCode.value][0]);
    const menus__ = computed(() => menus.value[languageCode.value]);
</script>
