<template>
    <header id="p-header" class="p-header group" ref="$header">
        <div class="c-container">
            <div class="p-header--grid">
                <div class="c-logo">
                    <NuxtLink
                        :to="
                            localePath({
                                name: 'index'
                            })
                        "
                        class="link-home"
                    >
                        <IconsGlogo />
                    </NuxtLink>
                </div>
                <nav class="c-navigation">
                    <ul class="parents">
                        <li v-for="item in menus__.menu_general" :key="item.id" class="item">
                            <NuxtLink :to="useConvertedLink(item.element, item)" class="item-link">
                                <span class="text">{{ item.title }}</span>
                            </NuxtLink>
                        </li>
                    </ul>
                </nav>
                <div class="c-custom" v-show="!isDesktop">
                    <UiHamburger />
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
    const localePath = useLocalePath();
    const store = useGlobalStore();
    const { menus, languageCode } = storeToRefs(store);

    const isDesktop = useMediaQuery("(min-width: 1181px)");

    const $header = ref(null);
    const menus__ = computed(() => menus.value[languageCode.value]);
</script>
